import React, { useContext, useEffect, useRef, useState } from 'react';
import './ProfileSideModal.css';
import CompanyInfos from '../Common/CompanyInfos/CompanyInfos';
import ProfileSection from './Components/ProfileSection';
import HeadButtons from './Components/HeadButtons';
import TagsBlock from './Components/TagsBlock';
import ContactFields from './Components/ContactFields';
import { createTask } from '../../../../services/api/leads.api';
import { actions, LeadsContext } from '../../store/leads.store';
import { FormattedMessage } from 'react-intl';
import ConfirmDeleteLead from './LeftWindow/common/ConfirmDeleteLead/ConfirmDeleteLead';
import { toast } from 'react-toastify';
import useProfileInfo from '../Hooks/ProfileInfo.hooks';
import AnimatedPopup from '../Common/AnimatedPopup/AnimatedPopup';
import AddNoteSection from '../Common/AddNoteSection/AddNoteSection';
import { addLeadNote, getLeadFeatures, leadNoteDelete } from '../../../../services/api/leads.api';
import NoteAddedMessage from '../Common/AddNoteSection/NoteAddedMessage';
import { checkPermission } from '../../../../services/utils';
import AddTaskSection from '../../Tasks/common/AddTaskSection/AddTaskSection';
import Aircall from '../../common/phoneIntegrations/Aircall/Aircall';
import Ringover from '../../common/phoneIntegrations/Ringover/Ringover';
import * as Permissions from '../../../../components/Permissions/Permissions';

function ProfileSideModal({
  profile,
  setModalToggle,
  handleEmailToggle,
  profilePermissions,
  organizationPlanPermissions,
  handleExportLeads,
  handleDeleteLead,
  deleteLeadErrorMessage,
  organization,
  checkPlanPermissions,
  checkMemberPermissions,
  organizationCredits,
}: any) {
  const containerRef = useRef(null);
  //@ts-expect-error
  const { state, dispatch } = useContext(LeadsContext);

  const memberExportPermission = checkPermission({
    permissions: profilePermissions,
    name: 'Integration',
    action: 'Export',
  });
  const planExportPermission = checkPermission({
    permissions: organizationPlanPermissions,
    name: 'Integration',
    action: 'Export',
  });
  const [usedState, setters, handlers] = useProfileInfo({
    profile,
    memberPermission: checkMemberPermissions('Edit'),
    planPermission: checkPlanPermissions('Edit'),
  });
  const [noteAdded, setNoteAdded] = useState(null);
  const fieldsAreChanged = JSON.stringify(profile) == JSON.stringify(usedState.leadToModify);
  useEffect(() => {
    if (profile) {
      setters.setShow(true);
    }
    document.addEventListener('click', handleOnClick);
    document.addEventListener('keyup', handleOnEscape);
    return () => {
      document.removeEventListener('click', handleOnClick);
      document.removeEventListener('keyup', handleOnEscape);
    };
  }, []);

  function handleOnClick(e: any) {
    if (e.target.contains(containerRef.current)) {
      setters.setShow(false);
      setTimeout(() => {
        setModalToggle();
      }, 100);
    }
  }

  function handleOnEscape(e: any) {
    if (e.key === 'Escape') {
      setters.setShow(false);
      setTimeout(() => {
        setModalToggle();
      }, 100);
    }
  }

  const handleClose = () => {
    setters.setShow(false);
    setTimeout(() => {
      setModalToggle();
    }, 100);
  };
  const toggleTemplate = (e: any, lead: any) => {
    if (e) e.stopPropagation();
    handleEmailToggle(lead);
  };

  const onLeadExport = (type: any) => {
    handleExportLeads(type, type == 'Integrations' ? [profile] : profile);
  };
  const handleAddNote = async (noteValue: any) => {
    if (!checkMemberPermissions('Edit')) {
      setters.setCreateNoteToggle(false);

      return toast.error(<FormattedMessage id={'permissions.noAccess'} />);
    }
    if (!checkPlanPermissions('Edit')) {
      setters.setCreateNoteToggle(false);

      return toast.error(<FormattedMessage id={'permissions.upgradePlan'} />);
    }
    const { statusCode, data } = await addLeadNote({
      text: noteValue,
      leadId: profile._id,
      clientDate: new Date(),
    });
    if (statusCode == 200) {
      setNoteAdded('success');
      const { data } = await getLeadFeatures({ leadId: profile._id });
      dispatch({ type: actions.LEAD_DETAILS, payload: data });
    } else {
      //@ts-expect-error
      toast.error(data.message);

      setNoteAdded('error');
    }
  };
  const handleGoToNotes = () => {
    //@ts-expect-error
    window.location = 'lead/' + profile._id + '?notes=true';
  };
  return (
    <div className={'profile-side-modal'}>
      <div
        className={'modal  right ' + (usedState.show ? 'show fade' : '')}
        style={{ display: 'block', background: 'rgba(0,0,0,.7)' }}
        id="right-sidebar-modal"
      >
        <div className="modal-dialog lead-overview" ref={containerRef}>
          <div className="modal-content">
            {/* <!-- Modal Header -->*/}

            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={handleClose}
                data-tracking-id={'leads.sideModal-close'}
              >
                <img src="/assets/images/ic-cross.png" alt="" />
              </button>
            </div>

            {/* <!-- Modal body -->*/}

            <div className="modal-body pt-0">
              <div className="inner-content">
                <ProfileSection profile={usedState.leadToModify} />

                <HeadButtons
                  setShow={setters.setShow}
                  toggleTemplate={toggleTemplate}
                  profile={profile}
                  setExportToggle={setters.setExportToggle}
                  exportToggle={usedState.exportToggle}
                  onLeadExport={(type: any) => {
                    onLeadExport(type);

                    setters.setLeadToModify(profile);
                  }}
                  onLeadDelete={() => {
                    setters.setLeadToModify(profile);

                    setters.setDeleteLeadToggle(!usedState.deleteLeadToggle);
                  }}
                  setDeleteLeadToggle={setters.setDeleteLeadToggle}
                  deleteLeadToggle={setters.deleteLeadToggle}
                  setCreateNoteToggle={() =>
                    setters.setCreateNoteToggle(!usedState.createNoteToggle)
                  }
                  setAircallToggle={setters.setAircallToggle}
                  setRingoverToggle={setters.setRingoverToggle}
                  phoneToggle={usedState.phoneToggle}
                  setPhoneToggle={setters.setPhoneToggle}
                  trackingFrom={'leads.sideModal'}
                  planExportPermission={checkPlanPermissions('Export')}
                  memberExportPermission={checkMemberPermissions('Export')}
                  planEditPermission={checkPlanPermissions('Edit')}
                  memberEditPermission={checkMemberPermissions('Edit')}
                  organizationPlan={organizationCredits && organizationCredits.plan}
                />

                <AnimatedPopup
                  toggle={usedState.deleteLeadToggle}
                  onCloseProfileSideModal={() => setters.setDeleteLeadToggle(false)}
                  themeColor={{
                    background: 'rgba(200,100,100,0.1)',
                    borderColor: '#fff',
                  }}
                >
                  <ConfirmDeleteLead
                    lead={usedState.leadToModify}
                    onConfirm={() => handleDeleteLead(usedState.leadToModify)}
                    deleteLeadErrorMessage={deleteLeadErrorMessage}
                    onCloseProfileSideModal={handleClose}
                    onCancel={() => setters.setDeleteLeadToggle(false)}
                    trackingFrom={'leads.sideModal'}
                  />
                </AnimatedPopup>

                <AnimatedPopup
                  toggle={usedState.createNoteToggle}
                  onCloseProfileSideModal={() => setters.setCreateNoteToggle(false)}
                  themeColor={{
                    background: 'rgba(0,100,200,0.1)',
                    borderColor: '#ccc',
                  }}
                >
                  {noteAdded ? (
                    <NoteAddedMessage
                      noteAdded={noteAdded}
                      goToNotes={handleGoToNotes}
                      onCancel={() => {
                        setters.setCreateNoteToggle(false);
                        setNoteAdded(null);
                      }}
                      createNewNote={() => setNoteAdded(null)}
                    />
                  ) : (
                    <AddNoteSection
                      onCancel={() => {
                        setters.setCreateNoteToggle(false);
                        setNoteAdded(null);
                      }}
                      addNote={handleAddNote}
                      goToNotes={handleGoToNotes}
                    />
                  )}
                </AnimatedPopup>
                {usedState.aircallToggle && (
                  <AnimatedPopup
                    toggle={usedState.aircallToggle}
                    onCloseProfileSideModal={() => setters.setAircallToggle(false)}
                    themeColor={{
                      background: 'rgba(0,179,136,0.3)',
                      borderColor: '#ccc',
                    }}
                  >
                    <Aircall
                      handleToggle={setters.setAircallToggle}
                      phones={profile && profile.phones}
                      leadId={profile && profile._id}
                    />
                  </AnimatedPopup>
                )}
                {usedState.ringoverToggle && (
                  <AnimatedPopup
                    toggle={usedState.ringoverToggle}
                    onCloseProfileSideModal={() => setters.setRingoverToggle(false)}
                    themeColor={{
                      background: 'rgb(0, 221, 208)',
                      borderColor: '#ccc',
                    }}
                  >
                    <Ringover
                      toggle={usedState.ringoverToggle}
                      handleToggle={setters.setRingoverToggle}
                      phones={profile && profile.phones}
                      leadId={profile && profile._id}
                    />
                  </AnimatedPopup>
                )}

                <section
                  className={
                    'content-container ' +
                    (usedState.deleteLeadToggle ||
                    usedState.createNoteToggle ||
                    usedState.aircallToggle
                      ? 'add-blur'
                      : '')
                  }
                >
                  <TagsBlock
                    selectToggle={usedState.selectToggle}
                    setSelectToggle={setters.setSelectToggle}
                    profilePermissions={profilePermissions}
                    organizationPlanPermissions={organizationPlanPermissions}
                    profile={profile}
                    organization={organization}
                    organizationPlan={organizationCredits && organizationCredits.plan}
                    fromSideModal
                  />

                  <ContactFields
                    profile={profile}
                    setEditLeadToggle={setters.setEditLeadToggle}
                    editLeadToggle={usedState.editLeadToggle}
                    moreEmails={usedState.moreEmails}
                    showMoreEmails={setters.showMoreEmails}
                    morePhones={usedState.morePhones}
                    showMorePhones={setters.showMorePhones}
                    onFieldsChange={handlers.onFieldsChange}
                    leadToModify={usedState.leadToModify}
                  />
                </section>
              </div>

              <section
                className={'content-container ' + (usedState.deleteLeadToggle ? 'add-blur' : '')}
              >
                <CompanyInfos
                  companyInfo={usedState.leadToModify.companyInfo}
                  onChange={(companyFields: any) =>
                    handlers.onFieldsChange({
                      value: companyFields,
                      property: 'companyInfo',
                    })
                  }
                />
              </section>

              {!fieldsAreChanged && usedState.saveAndCancelToggle && (
                <SaveAndCancel
                  onFieldsSubmit={handlers.onFieldsSubmit}
                  onCancel={() => {
                    setters.setLeadToModify(profile);

                    setters.setSaveAndCancelToggle(false);
                  }}
                  checkPlanPermissions={checkPlanPermissions}
                  checkMemberPermissions={checkMemberPermissions}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SaveAndCancel({ onFieldsSubmit, onCancel, checkPlanPermissions }: any) {
  const [slideUp, setSlideUp] = useState('');
  useEffect(() => {
    setTimeout(() => {
      setSlideUp(' slide-up');
    }, 0);
  }, []);
  const handleCancel = () => {
    onCancel();
  };
  return (
    <div className={'btns-modal-group ' + slideUp}>
      {checkPlanPermissions('Edit') ? (
        <button className={'btn btn1 '} onClick={onFieldsSubmit}>
          <FormattedMessage id={'save'} />
        </button>
      ) : (
        <Permissions.CallToAction isButton>
          <FormattedMessage id={'save'} />
        </Permissions.CallToAction>
      )}

      <button className={'btn btn2'} onClick={handleCancel}>
        <FormattedMessage id={'cancel'} />
      </button>
    </div>
  );
}

export default ProfileSideModal;

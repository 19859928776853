import React, { useEffect, useState } from 'react';
import { imageExists } from '../../../../../services/utils/tools';
import './ProfileSection.css';

function ProfileSection({ profile }: any) {
  const [picture, setPicture] = useState(null);
  useEffect(() => {
    imageExists(profile.pictureUrl).then((result) => {
      if (result) {
        setPicture(profile.pictureUrl);
      }
    });
  }, []);
  return (
    <div
      id="quick-view-profile"
      className={'profile-section ' + (profile && !profile.isViewDetails ? 'fixed' : '')}
    >
      <div className="profile-img-block red-bg">
        <div className="profile-img-block">
          {picture ? (
            <img src={picture} alt="" />
          ) : (
            <span className={'initials'} style={{ textTransform: 'uppercase' }}>
              {((profile.firstName && profile.firstName[0]) || '') +
                ((profile.lastName && profile.lastName[0]) || '')}
            </span>
          )}
        </div>
      </div>

      <div className="profile-info">
        <p className="username">
          <span className={'capital'}>{profile.firstName}</span>{' '}
          <span className={'capital'}>{profile.lastName}</span>
        </p>

        <span className="designation">{profile.job} </span>

        <ul className="social-list" style={{ padding: 0 }}>
          <li>
            <a
              href={'https://linkedin.com/in/' + profile.linkedin}
              target={'_blank'}
              className="lnkdn-icon active"
              rel="noopener noreferrer nofollow"
            >
              <span className="icon"></span>
            </a>
          </li>
          {/* <li>
                        <a href="#" className="twitter-icon">
                          <span className="icon"></span>
                        </a>
                      </li>*/}
        </ul>
      </div>
    </div>
  );
}

export default ProfileSection;

import React, { useContext, useEffect, useRef, useState } from 'react';

import { LeadsContext, actions } from '../../store/leads.store';
import './LeadsNav.css';
import { leadsFilter } from '../../../../services/api/leads.api';
import 'react-calendar/dist/Calendar.css';
import { Animation, ModalMessage, Permissions, Tooltip } from '../../../../components';
import { Button, OverlayTrigger } from 'react-bootstrap';
import CreateNewListModal from '../CreateNewListModal/CreateNwListModal';
import { deleteLabel, getLabels, editLabel } from '../../../../services/api/labels.api';
import { checkIsPlanLimited } from '../../../../services/utils/checkPermissions';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { getMembers } from '../../../../services/api/organization.api';
import { debounce } from '../../../../services/utils/tools';
import CustomToolTip from '../../../../components/CustomToolTip/CustomToolTip';
import { capitalizeFirstLetter } from '../../../../services/utils/tools';

const LabelItem = ({ label, onLabelSelect, setListToDelete, lisToEdit, setListToEdit }: any) => {
  const intl = useIntl();
  const handleLabelSelect = (e: any) => {
    if (label.id !== lisToEdit) onLabelSelect(label);
  };

  return (
    <li style={{ display: 'flex', alignItems: 'center' }}>
      <div
        className="radio_btn_wrap "
        onClick={handleLabelSelect}
        style={{ width: '100%' }}
        data-tracking-id={'leads.filters-list-item'}
      >
        <label>
          <input type="radio" name="filter" value="All Leads" />

          <input type="radio" name="filter" value={intl.formatMessage({ id: 'leads.allLeads' })} />

          <span className="radio-icon"></span>

          <div>
            <span className="label_text">{label.name}</span>
          </div>
        </label>
      </div>

      <span
        onClick={(e) => setListToEdit(e, label)}
        style={{ cursor: 'pointer', marginRight: '7px' }}
      >
        <img src="/assets/images/ic_edit_g.png" alt="" width={14} />
      </span>

      <span onClick={() => setListToDelete(label)} style={{ cursor: 'pointer', right: '0' }}>
        <img src="/assets/images/icon-delete.svg" alt="" />
      </span>
    </li>
  );
};

const MemberItem = ({ member, onMemberSelect }: any) => {
  return (
    <li onClick={() => onMemberSelect(member)}>
      <a
        href="#"
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span className="name_tag light_red" style={{ textTransform: 'uppercase' }}>
          {(member.firstName ? member.firstName[0] : '') +
            (member.lastName ? member.lastName[0] : '')}
        </span>

        <span className="name_ui">
          {' '}
          {capitalizeFirstLetter(member.firstName) + ' ' + capitalizeFirstLetter(member.lastName)}
        </span>
      </a>
    </li>
  );
};

function LeadsNav({ organizationCredit, setPage }: any) {
  //@ts-expect-error
  const { state, dispatch } = useContext(LeadsContext);
  const [date, setDate] = useState(null);
  const [dateToggle, setDateToggle] = useState(false);
  const [selectToggle, setSelectToggle] = useState(false);
  const [createLabelToggle, setCreateLabelToggle] = useState(false);
  const [editLabelToggle, setEditLabelToggle] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [listToDelete, setListToDelete] = useState(null);
  const [listToEdit, setListToEdit] = useState(null);
  const [addedBySearch, setAddedBySearch] = useState('');
  const [isCreateListAllowed, setIsCreateListAllowed] = useState(true);
  const intl = useIntl();
  useEffect(() => {
    if (organizationCredit.plan.maxLists !== -1) {
      setIsCreateListAllowed(
        state.labels && state.labels.length <= organizationCredit.plan.maxLists,
      );
    }
  }, [state.labels]);

  const handleSetListToEdit = (e: any, label: any) => {
    if (e) e.stopPropagation();
    setListToEdit(label);
    setEditLabelToggle(true);
  };

  const handleFilter = debounce(async ({ member, label, search = '' }: any) => {
    const leadsData = state.dataForm;
    leadsData.search = search;
    if (member) {
      leadsData.addedBy = member.email;
    } else {
      leadsData.addedBy = '';
    }
    if (label) {
      leadsData.labelId = label.id;
      dispatch({ type: actions.SELECT_LABEL, payload: label });
    } else {
      leadsData.labelId = state.dataForm.labelId;
    }

    leadsData.page = 1;

    if (state.selectedList) {
      leadsData.tagsIds = [state.selectedList._id];
    } else {
      leadsData.tagsIds = null;
    }

    const results = await leadsFilter({ data: leadsData });

    dispatch({
      type: actions.GET_LEADS,
      //@ts-expect-error
      payload: results.data.leads,
      //@ts-expect-error
      pages: results.data.pages,
    });

    dispatch({
      type: actions.LEADS_FILTER,
      payload: { ...leadsData, page: 1 },
    });
    if (setPage) setPage(1);
    setTriggerFilter(!triggerFilter);
  });
  const handleDatePickerToggle = () => {
    setDateToggle(!dateToggle);
  };
  const handleFilterByDate = (date: any) => {
    setDate(date);
    const formatedDate = new Date(date);
    const leadsData = { ...state.dataForm, date: formatedDate };
    dispatch({
      type: actions.LEADS_FILTER,
      payload: { ...leadsData, page: 1 },
    });
    dispatch({
      type: actions.LEADS_LOADING,
      payload: true,
    });
    leadsFilter({ data: leadsData }).then(({ data }) => {
      dispatch({
        type: actions.GET_LEADS,
        //@ts-expect-error
        payload: data.leads,
        //@ts-expect-error
        pages: data.pages,
      });
      dispatch({
        type: actions.LEADS_LOADING,
        payload: false,
      });
    });
  };
  const handleLabelDelete = async () => {
    setListToDelete(false);

    const { data, statusCode } = await deleteLabel(listToDelete.id);
    if (statusCode == 200) {
      getLabels().then((data) => {
        //@ts-expect-error
        dispatch({ type: actions.GET_LABELS, payload: data.labels });
      });
    }
  };
  // useEffect(() => {
  //   dispatch({
  //     type: actions.LEADS_LOADING,
  //     payload: true,
  //   });
  //   leadsFilter({ data: state.dataForm }).then(({ data }) => {
  //     dispatch({
  //       type: actions.GET_LEADS,
  //       //@ts-expect-error
  //       payload: data.leads,
  //       //@ts-expect-error
  //       pages: data.pages,
  //     });
  //     dispatch({
  //       type: actions.LEADS_LOADING,
  //       payload: false,
  //     });
  //   });
  // }, [triggerFilter]);

  const addedByFilter = async ({ search }: any) => {
    setAddedBySearch(search);
    const { data, statusCode } = await getMembers({ search });
    if (statusCode == 200) {
      dispatch({
        type: actions.GET_MEMBERS,
        //@ts-expect-error
        payload: data.members,
      });
      dispatch({
        type: actions.LEADS_FILTER,
        payload: { ...state.dataForm, page: 1 },
      });

      if (setPage) setPage(1);
    }
  };

  return (
    <section className="leads-nav-container">
      <div className="page-header all-leads__ctn">
        <div>
          <div className="search-ui" data-tracking-id={'leads.filters-searchInput'}>
            <FormattedMessage id={'leads.search'}>
              {(placeholder) => (
                <input
                  type="text"
                  name="search"
                  //@ts-expect-error
                  placeholder={placeholder}
                  onChange={(e) => handleFilter({ search: e.target.value })}
                  autoComplete={'off'}
                  style={
                    checkIsPlanLimited({
                      permissions: organizationCredit && organizationCredit.plan.permissions,
                      name: 'Leads',
                    })
                      ? { background: '#ccc' }
                      : {}
                  }
                  disabled={checkIsPlanLimited({
                    permissions: organizationCredit && organizationCredit.plan.permissions,
                    name: 'Leads',
                  })}
                />
              )}
            </FormattedMessage>
          </div>
        </div>

        <div className="cstm-dropdown addedby user_filter">
          <div className="dropdown">
            <Button
              variant={'primary'}
              className="dropdown-toggle"
              //@ts-expect-error
              onClick={() => setSelectToggle('addedby')}
              disabled={checkIsPlanLimited({
                permissions: organizationCredit && organizationCredit.plan.permissions,
                name: 'Leads',
              })}
              style={{ minWidth: 'max-content' }}
              data-tracking-id={'leads.filter-addedBy'}
            >
              <FormattedMessage id={'leads.addedBy'} />
            </Button>

            <Animation.Appear
              className={'dropdown-menu show'}
              //@ts-expect-error
              toggle={selectToggle === 'addedby' ? selectToggle : false}
              setToggle={setSelectToggle}
            >
              <div className={'search-input'}>
                <i className="fas fa-search"></i>

                <input
                  type="text"
                  style={{ border: 'none' }}
                  placeholder={intl.formatMessage({
                    id: 'leads.search',
                  })}
                  onChange={(e) => addedByFilter({ search: e.target.value })}
                  value={addedBySearch}
                />
              </div>

              <ul>
                <li
                  onClick={() => {
                    addedByFilter({ search: '' });
                    setSelectToggle(false);
                    handleFilter({ search: '' });
                  }}
                >
                  <a href="#" data-tracking-id={'leads.filter-addedBy-all'}>
                    <span
                      className="name_tag light_red first-all"
                      style={{ textTransform: 'uppercase' }}
                    >
                      <FormattedMessage id={'leads.all'} />
                    </span>

                    <span className="name_ui" style={{ color: '#3b53d1' }}>
                      <FormattedMessage id={'organization.allMembers'} />
                    </span>
                  </a>

                  <hr />
                </li>
                {state.members && state.members.length > 0 ? (
                  state.members.map((member: any, i: any) => (
                    <MemberItem
                      key={i}
                      member={member}
                      onMemberSelect={(member: any) => {
                        handleFilter({ member });

                        setAddedBySearch(member.firstName + ' ' + member.lastName);
                        setSelectToggle(false);
                      }}
                    />
                  ))
                ) : (
                  <li style={{ textAlign: 'center' }}>
                    <a href="#">
                      <span className="name_ui">
                        <FormattedMessage id={'workflow.noResultsFound'} />
                      </span>
                    </a>
                  </li>
                )}
              </ul>
            </Animation.Appear>
          </div>
        </div>
        {createLabelToggle && (
          <CreateNewListModal
            toggle={createLabelToggle}
            setToggle={setCreateLabelToggle}
            setCurrentToggle={setSelectToggle}
            tracking={{
              action: 'leads.filters-list-createNewListPopUp-create',
              cancel: 'leads.filters-list-createNewListPopUp-cancel',
            }}
          />
        )}
        {editLabelToggle && (
          <CreateNewListModal
            toggle={editLabelToggle}
            setToggle={setEditLabelToggle}
            setCurrentToggle={setSelectToggle}
            label={listToEdit}
            tracking={{
              action: 'leads.filters-list-createNewListPopUp-edit',
              cancel: 'leads.filters-list-createNewListPopUp-cancel',
            }}
          />
        )}
      </div>
    </section>
  );
}

export default LeadsNav;

import React, { useContext, useEffect, useRef, useState } from 'react';
import './TagsList.css';
import { Animation, Tooltip } from '../../../../components';
import colors from './colors';
import { OverlayTrigger } from 'react-bootstrap';
import {
  addLeadTag,
  deleteLeadTag,
  getLeadFeatures,
  searchLeadTag,
  updateLeadTag,
} from '../../../../services/api/leads.api';
import { getOrganization } from '../../../../services/api/organization.api';
import { debounce } from '../../../../services/utils/tools';

import { actions, LeadsContext } from '../../store/leads.store';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';

export default function TagsList({
  leadId,
  selectTag,
  style,
  withoutTitle,
  fromSideModal,
  trackingFrom = '',
  maxTags,
}: any) {
  const [tagToggle, setTagToggle] = useState(false);
  const [colorsListToggle, setColorsListToggle] = useState(false);
  const [tagInputToggle, setTagInputToggle] = useState(false);
  const [editTagToggle, setEditTagToggle] = useState(false);
  //@ts-expect-error
  const { dispatch, state } = useContext(LeadsContext);
  const [createdTag, setCreatedTag] = useState({
    value: '',
    color: '',
  });
  const [tagToEdit, setTagToEdit] = useState<any>({
    value: '',
    color: '',
  });
  const [isCreateTagAllowed, setIsCreateTagAllowed] = useState(true);
  const [generatedColors, setGeneratedColors] = useState([]);
  const [tagToDeleteToggle, setTagToDeleteToggle] = useState(false);
  const listColorsRef = useRef(null);
  const [tags, setTags] = useState(null);
  const [isTriggered, setIsTriggered] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    const getUpdatedTags = async () => {
      const { data, statusCode } = await getOrganization();
      if (statusCode === 200) {
        //@ts-expect-error
        setTags(data.tags);
        //@ts-expect-error
        dispatch({ type: actions.GET_ALL_TAGS, payload: data.tags });
      }
    };
    getUpdatedTags();
  }, [isTriggered]);

  useEffect(() => {
    setGeneratedColors(colors());
    setCreatedTag({ ...createdTag, color: 'black' });
    if (maxTags !== -1 && tags && tags.length) {
      setIsCreateTagAllowed(tags.length <= maxTags);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [createdTag, tagToEdit]);

  async function handleClickOutside(e: any) {
    if (listColorsRef.current && !listColorsRef.current.contains(e.target)) {
      setColorsListToggle(false);
      if (createdTag.value && createdTag.color) {
        //@ts-expect-error
        await handleCreateTag();
      } else {
        setTagInputToggle(false);
      }
      if (tagToEdit.value && tagToEdit.color) {
        //@ts-expect-error
        await handleEditTag();
      } else {
        setEditTagToggle(false);
      }
    }
  }
  const handleRegenerateColors = () => {
    setGeneratedColors(colors());
    // setTagColor(colors()[0]);
  };

  const handleCreateTag = async (e: any) => {
    if (e) e.preventDefault();
    console.log('e ->', e);
    console.log('CREATED TAG ->', createdTag);
    if (createdTag.value && createdTag.color) {
      const { statusCode, data } = await addLeadTag({
        value: createdTag.value,
        color: createdTag.color,
        clientDate: new Date(),
      });
      if (statusCode == 200) {
        setTagInputToggle(false);
        setCreatedTag({ value: '', color: 'black' });
        let allTags = Object.assign([], tags);
        //@ts-expect-error
        allTags.unshift(data.tag);

        setIsTriggered((prevState) => !prevState);

        //-------- tag lead(s) ----
        //@ts-expect-error
        selectTag(data.tag);
        //*******
      } else {
        //@ts-expect-error
        toast.error(data.message);
      }
    }
  };

  const handleEditTag = async (e: any) => {
    if (e) e.preventDefault();
    if (tagToEdit.value && tagToEdit.color) {
      const { statusCode, data } = await updateLeadTag({
        clientDate: new Date(),
        ...tagToEdit,
      });
      if (statusCode == 200) {
        let allTags = Object.assign([], tags);
        allTags = allTags.map((tag: any) => {
          if (tag._id == tagToEdit._id) {
            tag = tagToEdit;
          }
          return tag;
        });
        if (leadId) {
          const { statusCode: leadFeaturesStatus, data: leadFeaturesData } = await getLeadFeatures({
            leadId,
          });
          if (leadFeaturesStatus == 200) {
            dispatch({
              type: actions.LEAD_DETAILS,
              payload: leadFeaturesData,
            });
          }
          dispatch({
            type: actions.GET_ALL_TAGS,
            payload: allTags,
          });
          setEditTagToggle(false);
        }
        setIsTriggered((prevState) => !prevState);
      } else {
        //@ts-expect-error
        toast.error(data.message);
      }
    }
  };

  const handleDeleteTag = async (tagToDelete: any) => {
    const { statusCode, data } = await deleteLeadTag({
      _id: tagToDelete._id,
    });

    if (statusCode == 200) {
      let allTags = Object.assign([], tags);
      allTags = allTags.filter((tag: any) => tag._id !== tagToDelete._id);

      if (leadId) {
        const { statusCode: leadFeaturesStatus, data: leadFeaturesData } = await getLeadFeatures({
          leadId,
        });
        if (leadFeaturesStatus == 200) {
          dispatch({
            type: actions.LEAD_DETAILS,
            payload: leadFeaturesData,
          });
        }
      }
      setIsTriggered((prevState) => !prevState);
    } else {
      // @ts-expect-error
      toast.error(data.message);
    }

    setEditTagToggle(false);
  };

  const handleSearchTag = debounce(async (value: any) => {
    const { data } = await searchLeadTag({ search: value });
    if (value.trim().length > 0) {
      //@ts-expect-error
      setTags(data.tags);
    } else {
      setIsTriggered((prevState) => !prevState);
    }
  }, 200);

  return (
    <div id={'tags_list'} className={'tag-fields cstm-dropdown  add-to-list '} style={style}>
      {!withoutTitle && (
        <div className={'custom-title'} style={{ marginRight: '1rem' }}>
          <FormattedMessage id="leads.lists" />
        </div>
      )}

      <div className="dropdown">
        {tagToggle && isCreateTagAllowed ? (
          <input
            type="text"
            id={'search-tag-input'}
            placeholder={intl.formatMessage({ id: 'leads.searchList' })}
            onChange={(e) => handleSearchTag(e.target.value)}
            autoFocus={true}
          />
        ) : (
          <div
            className="white-button"
            onClick={() => setTagToggle(true)}
            //disabled={planIsLimitedPermission}
            data-tracking-id={trackingFrom + '-tags'}
          >
            <img
              src="/assets/images/ic-add.svg"
              alt=""
              style={{ width: 20, height: 20, marginRight: 10, opacity: 0.6 }}
            />

            <p style={{ width: 'max-content', fontSize: 14 }}>
              <FormattedMessage id="leads.lists" />
            </p>
          </div>
        )}

        <Animation.Appear
          className={'dropdown-menu show'}
          toggle={tagToggle}
          setToggle={(val: any) => {
            setTagToggle(false);
            setColorsListToggle(false);
            setEditTagToggle(false);
            setTagInputToggle(false);
            //setTagColor({});
          }}
          style={{ width: 280, zIndex: 10 }}
        >
          <div className={'tags-list-select'}>
            {isCreateTagAllowed && (
              <>
                {!tagInputToggle ? (
                  <div className={''}>
                    <span
                      className="add-tag"
                      onClick={() => {
                        setTagInputToggle(true);

                        setEditTagToggle(null);
                      }}
                      data-tracking-id={trackingFrom + '-tags-addTag'}
                    >
                      + {intl.formatMessage({ id: 'addList' })}
                    </span>
                  </div>
                ) : (
                  <form
                    onSubmit={handleCreateTag}
                    className={'add-custom-tag'}
                    style={{ borderColor: createdTag.color }}
                    ref={listColorsRef}
                  >
                    <input
                      type="text"
                      placeholder={intl.formatMessage({ id: 'listName' })}
                      onChange={(e) =>
                        setCreatedTag({
                          ...createdTag,
                          value: e.target.value,
                        })
                      }
                      value={createdTag.value}
                      autoFocus={true}
                    />

                    <span
                      className={'tag-color'}
                      onClick={() => setColorsListToggle(!colorsListToggle)}
                      style={{
                        background: createdTag.color || generatedColors[0],
                      }}
                      data-tracking-id={trackingFrom + '-tags-tagsColors'}
                    >
                      <i className="fas fa-fill-drip"></i>
                    </span>
                    {colorsListToggle && (
                      <div className={'tag-colors'}>
                        <OverlayTrigger
                          placement={'top'}
                          key={'regenerate-colors'}
                          overlay={(props) =>
                            Tooltip({
                              ...props,
                              //data: intl.formatMessage({ id: "leads.quickView" }),
                              data: intl.formatMessage({
                                id: 'leads.regenerateColors',
                              }),
                              container_style: {
                                padding: '0px 5px',
                              },
                            })
                          }
                          defaultShow={false}
                        >
                          <i
                            className="fas fa-sync"
                            onClick={handleRegenerateColors}
                            data-tracking-id={trackingFrom + '-tags-generateColors'}
                          />
                        </OverlayTrigger>
                        {generatedColors.map((color, i) => (
                          <span
                            key={i}
                            style={{ background: color }}
                            className={'tag-color'}
                            onClick={() => {
                              setCreatedTag({
                                ...createdTag,
                                color,
                              });
                              setColorsListToggle(false);
                            }}
                            data-tracking-id={trackingFrom + '-tags-chooseColor'}
                          />
                        ))}
                        {/*  <i
                      className="fas fa-times"
                      onClick={() => setColorsListToggle(false)}
                    ></i>*/}
                      </div>
                    )}
                  </form>
                )}
              </>
            )}

            <ul>
              {tags &&
                tags.map((tag: any, i: any) => (
                  <li className="ui-tag-color" key={i}>
                    {tagToEdit._id == tag._id && editTagToggle ? (
                      <form
                        onSubmit={handleEditTag}
                        className={'add-custom-tag'}
                        ref={listColorsRef}
                        style={{ borderColor: tagToEdit.color }}
                      >
                        <input
                          type="text"
                          placeholder={intl.formatMessage({
                            id: 'listName',
                          })}
                          onChange={(e) =>
                            setTagToEdit({
                              ...tagToEdit,
                              value: e.target.value,
                            })
                          }
                          value={tagToEdit.value}
                        />

                        <span
                          className={'tag-color'}
                          onClick={() => setColorsListToggle(!colorsListToggle)}
                          style={{
                            background: tagToEdit.color || generatedColors[0],
                          }}
                          autoFocus={true}
                        >
                          <i className="fas fa-fill-drip"></i>
                        </span>
                        {colorsListToggle && (
                          <div className={'tag-colors'}>
                            {generatedColors.map((color, i) => (
                              <span
                                key={i}
                                style={{ background: color }}
                                className={'tag-color'}
                                onClick={() => {
                                  setTagToEdit({
                                    ...tagToEdit,
                                    color,
                                  });
                                  setColorsListToggle(false);
                                }}
                              />
                            ))}
                          </div>
                        )}
                      </form>
                    ) : (
                      <span
                        className={'tag-name'}
                        style={{
                          background: tag.color + '1A',
                          color: tag.color,
                        }}
                      >
                        <span
                          onClick={() => {
                            selectTag(tag);
                            setTagToggle(false);
                          }}
                        >
                          {tag.value}
                        </span>

                        <span className={'manage-tag'}>
                          <img
                            className={'edit'}
                            src="/assets/images/ic_edit_g.png"
                            alt=""
                            onClick={() => {
                              setEditTagToggle(true);
                              setTagToEdit(tag);
                              setTagInputToggle(false);
                            }}
                            data-tracking-id={trackingFrom + '-tags-editTag'}
                          />
                          {tagToDeleteToggle ? (
                            <OverlayTrigger
                              placement={'top'}
                              key={'delete-tag'}
                              overlay={(props) =>
                                Tooltip({
                                  ...props,
                                  data: (
                                    <div
                                      style={{
                                        background: 'white',
                                        color: 'red',
                                        padding: 5,
                                        borderRadius: 5,
                                        fontSize: 15,
                                      }}
                                    >
                                      <FormattedMessage id={'leads.confirmTagDelete'} />
                                    </div>
                                  ),
                                  container_style: {
                                    padding: '3px',
                                  },
                                })
                              }
                              defaultShow={true}
                              onToggle={(status) => !status && setTagToDeleteToggle(false)}
                            >
                              <img
                                className={'delete'}
                                src="/assets/images/icon-delete.svg"
                                onClick={() => handleDeleteTag(tag)}
                                data-tracking-id={trackingFrom + '-tags-deleteTag'}
                              />
                            </OverlayTrigger>
                          ) : (
                            <img
                              className={'delete'}
                              src="/assets/images/icon-delete.svg"
                              onClick={() => handleDeleteTag(tag)}
                              data-tracking-id={trackingFrom + '-tags-deleteTag'}
                            />
                          )}
                        </span>
                      </span>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </Animation.Appear>
      </div>
    </div>
  );
}

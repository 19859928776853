import React, { useEffect, useState } from 'react';
import { FormattedDate, useIntl } from 'react-intl';

import DataNotFound from '../../../../../components/DataNotFound/DataNotFound';
import activitiesTemplates from './data';

import FieldsTable from './FieldsTable/FieldsTable';
import activityTemplate from './ActivityTemplate';
export default function Activity({ activities, profile }: any) {
  const [templates, setTemplates] = useState([]);
  const intl = useIntl();
  useEffect(() => {
    let activitiesData = activities.map((tempActivity: any) => {
      //@ts-expect-error
      let templateValue = activityTemplate[tempActivity.template].value[intl.locale];
      //@ts-expect-error
      let templateName = activityTemplate[tempActivity.template].name[intl.locale];
      //@ts-expect-error
      activityTemplate[tempActivity.template].fields.forEach((variable: any, i: any) => {
        try {
          let fieldValue = tempActivity.data[variable];
          let htmlStart = '';
          let htmlEnd = '';
          if (variable === 'member' || variable === 'lead') {
            htmlStart =
              "<span style='color:#555; text-transform:capitalize;border-bottom:1px dashed #555' >";
            htmlEnd = '</span> ';
          }
          templateValue = templateValue.replace(
            '{{' + variable + '}}',
            htmlStart + fieldValue + htmlEnd,
          );
        } catch (err) {}
      });
      return { ...tempActivity, templateValue, templateName };
    });
    setTemplates(activitiesData);
  }, [activities]);
  return (
    <div
      id="activity_tab"
      className={'tab-pane fade ' + (!profile.isViewNotes ? 'active show' : '')}
    >
      <div className="activity-content">
        {templates.length > 0 ? (
          templates.map((activity, i) => <ActivityCard key={i} activity={activity} />)
        ) : (
          <DataNotFound />
        )}
      </div>
    </div>
  );
}

function ActivityCard({ activity }: any) {
  return (
    <div className="ac-card">
      <div className="row top-block">
        <div className="col-lg-6 align-self-center">
          <h5 className="sub-title" style={{ position: 'relative' }}>
            <span className="check-icon" style={{ left: -25 }}></span> {activity.templateName}
          </h5>
        </div>

        <div className="col-lg-6 align-self-center">
          <span className="date-ui">
            <FormattedDate
              value={activity.clientDate}
              year="numeric"
              month="long"
              day="numeric"
              weekday="long"
              hour={'numeric'}
              minute={'numeric'}
            />
          </span>
        </div>
      </div>

      <p className="desc ">
        <span
          dangerouslySetInnerHTML={{
            __html: activity.templateValue,
          }}
        />
      </p>
      {activity.data && activity.data.leadChanges ? (
        <FieldsTable fields={activity.data.leadChanges} />
      ) : (
        ''
      )}
    </div>
  );
}

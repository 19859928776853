import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './CustomToolTip.css';

export default function CustomToolTip({
  children,
  element,
  style,
  position = 'top',
  tooltipContainerStyle,
  onlyTooltip,
  scrollableClass,
}: any) {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const containerRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  // Function to calculate and set the tooltip's position
  const updateTooltipPosition = () => {
    if (containerRef.current && tooltipRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      let top, left;

      switch (position) {
        case 'top':
          top = containerRect.top - tooltipRect.height - 15 + window.scrollY; // Adjust spacing
          left =
            containerRect.left + (containerRect.width - tooltipRect.width) / 2 + window.scrollX;
          break;
        case 'bottom':
          top = containerRect.bottom + 8 + window.scrollY;
          left =
            containerRect.left + (containerRect.width - tooltipRect.width) / 2 + window.scrollX;
          break;
        case 'left':
          top =
            containerRect.top + (containerRect.height - tooltipRect.height) / 2 + window.scrollY;
          left = containerRect.left - tooltipRect.width - +window.scrollX;
          break;
        case 'right':
          top =
            containerRect.top + (containerRect.height - tooltipRect.height) / 2 + window.scrollY;
          left = containerRect.right + 8 + window.scrollX;
          break;
        default:
          top = containerRect.top - tooltipRect.height - 8 + window.scrollY;
          left =
            containerRect.left + (containerRect.width - tooltipRect.width) / 2 + window.scrollX;
      }

      setTooltipPosition({ top, left });
    }
  };

  // Trigger position update when visibility changes
  useEffect(() => {
    if (isVisible) {
      updateTooltipPosition();
    }
  }, [isVisible]);

  // Update position dynamically on resize or scroll
  useEffect(() => {
    if (isVisible) {
      window.addEventListener('resize', () => {
        setIsVisible(false);
        updateTooltipPosition();
      });
      window.addEventListener('scroll', () => {
        setIsVisible(false);
        updateTooltipPosition();
      });
    }
    if (scrollableClass) {
      let scrollableSection = document.querySelector('.' + scrollableClass);
      if (scrollableSection) {
        scrollableSection.addEventListener('scroll', () => setIsVisible(false));
      }
    }

    return () => {
      window.removeEventListener('resize', updateTooltipPosition);
      window.removeEventListener('scroll', updateTooltipPosition);
    };
  }, [isVisible]);

  // Tooltip rendering inside a portal
  const tooltipContent = isVisible && element && (
    <div
      ref={tooltipRef}
      className={`custom-toolTip ${position} ${onlyTooltip ? 'onlyTooltip' : ''}`}
      style={{
        ...style,
        position: 'absolute',
        top: `${tooltipPosition.top}px`,
        left: `${tooltipPosition.left}px`,
        zIndex: 1000,
      }}
    >
      {Array.isArray(element)
        ? element.map((item, index) => (
            <div key={index} style={{ margin: '4px 0' }}>
              {item.email ? item.email : item}
            </div>
          ))
        : element}
      {!onlyTooltip && <div className={`custom-toolTip-index ${position}`}></div>}
    </div>
  );

  return (
    <>
      <div
        ref={containerRef}
        style={{ width: 'max-content', ...tooltipContainerStyle }}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {ReactDOM.createPortal(tooltipContent, document.body)}
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import './PlanCard.css';
import useSelectUser from '../SelectUser/SelectUser.hooks';
import usePlans from '../../Plans/plans.hooks';
import CustomToolTip from '../../../../components/CustomToolTip/CustomToolTip';
import { tooltipIds } from './utils';
import CurrencyDisplay from '../../../../components/CurrencyDisplay/CurrencyDisplay';
import { getUpdatedSelectedPlan } from '../../../../services/utils';
import { useEvaluatedFlags } from '../../../../providers/LDProvider/LDProvider';
import PlanPermissions from '../PlanPermissions/PlanPermissions';
import NumberFormatter from '../../../../components/NumberFormatter/NumberFormatter';
import useChristmasDiscount from '../../../../services/utils/hooks/useChristmasCoupon';
import LearnAboutAddons from './common/LearnAboutAddons/LearnAboutAddons';

export default function PlanCard({
  plan,
  selectPlan,
  subscriptionType,
  organizationCredits,
  seats,
  onCompareToggle,
  setAddCredits,
  selectedPlan,
  isCurrentSelected,
  plans,
  handleSeats,
  minSeats,
  isCustom,
  deactivatePlan,
  isVersion2,
  user,
}: any) {
  const [currentPlan, setCurrentPlan] = useState(plan);
  const [current, setCurrent] = useState(null);
  const { usersAndCredits, defaultUsersAndCredits } = useSelectUser({
    planId: plan.planId,
    minSeats,
  });
  const [selectedUser, setSelectedUser] = useState(defaultUsersAndCredits);
  const staticPlan = usePlans(plan, isCustom);
  const [isFreePlan, setIsFreePlan] = useState(
    organizationCredits.plan.planId === '0' ? true : false,
  );
  const flags: any = useEvaluatedFlags(['learn-about-addons']);
  const { isEligibleForDiscount } = useChristmasDiscount(flags, organizationCredits);

  useEffect(() => {
    let thisPlan = { ...plan };
    if (
      organizationCredits &&
      organizationCredits.plan &&
      organizationCredits.plan.version == 1 &&
      organizationCredits.plan.planId === thisPlan.planId
    ) {
      thisPlan.yearly = organizationCredits.plan.numberOfMonths === 12;
    } else {
      if (isCustom) return;
      if (subscriptionType === 'yearly') {
        thisPlan.yearly = true;
      } else {
        thisPlan.yearly = false;
      }
    }
    setCurrentPlan(thisPlan);
    if (organizationCredits && organizationCredits.plan) {
      setCurrent(
        (organizationCredits.plan.planId == thisPlan.planId && thisPlan.planId == '0') ||
          (organizationCredits.plan.numberOfMonths == 12 &&
            organizationCredits.plan.planId == thisPlan.planId &&
            thisPlan.yearly) ||
          (organizationCredits.plan.numberOfMonths !== 12 &&
            organizationCredits.plan.planId == thisPlan.planId &&
            !thisPlan.yearly),
      );
    } else {
      setCurrent(thisPlan.planId == '0');
    }

    if (
      organizationCredits &&
      organizationCredits.plan.version == 1 &&
      thisPlan.planId == 'plan_12'
    ) {
      setCurrent(true);
    }
    if (subscriptionType == 'monthly' && plan.planId == 'plan_3') {
      setCurrentPlan({ ...currentPlan, yearly: true });
    }

    if (subscriptionType == 'yearly' && plan.planId == '0') {
      setCurrentPlan({ ...currentPlan, yearly: false });
    }
  }, [subscriptionType, plans]);

  const handleSelectPlan = (plan: any, type: any) => {
    if (type) {
      setAddCredits({ type, active: true });
    }
    selectPlan({
      ...plan,
      addCreditsToggle: type ? { type, active: true } : undefined,
    });
  };
  const handleSelectSeats = (users: any) => {
    setCurrentPlan({ ...currentPlan, numberOfSeats: users });
    handleSeats(users, plan.planId);
  };

  const handlePlanCardClick = () => {
    if (currentPlan.planId !== '0') {
      let updatedSelectedPlan = null;

      if (currentPlan.planId === organizationCredits.plan.planId) {
        updatedSelectedPlan = getUpdatedSelectedPlan(currentPlan, organizationCredits.plan);
        // Get the organizationPlan values
        //@ts-expect-error
        handleSelectPlan(updatedSelectedPlan);
      } else {
        // Current predefined plan values
        //@ts-expect-error
        handleSelectPlan(currentPlan);
      }
    }
  };

  if (!currentPlan) return <div />;

  return (
    <div
      id={'plan-card'}
      onClick={handlePlanCardClick}
      className={current ? 'plan-card-selected' : 'plan-card-default'}
    >
      <div
        className={!isCurrentSelected || deactivatePlan ? 'deactivate-plan' : 'activate-plan'}
        style={{
          paddingBottom: isFreePlan ? '60px' : '',
        }}
      >
        {/* MIN X LICENSES TAG */}
        {currentPlan.planId === 'plan_3' && (
          <div className="plan-card-tag">
            <FormattedMessage id="billing.plan.minXLicenses" values={{ value: '5' }} />
          </div>
        )}

        <div className={'plan-card-header'}>
          {/* PLAN NAME */}

          <div
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <h2 className={'plan-card-title'}>
              {currentPlan.name === 'Kaspr Free' ? (
                <FormattedMessage id="organization.filters.recurrentCredits" />
              ) : (
                currentPlan.name
              )}
            </h2>
            {currentPlan.planId === 'plan_2' &&
              !flags['christmasCoupon'] &&
              (organizationCredits.plan.planId === '0' ||
                organizationCredits.plan.planId === 'plan_1') && (
                <div className="discount_tag">
                  <FormattedMessage
                    id="billing.plan.saveXPercent"
                    values={{
                      value: '20',
                    }}
                  />
                </div>
              )}
            {/* CHRISTMAS -30% TAG */}
            {isEligibleForDiscount &&
              // Don't show tag on free plan
              currentPlan.planId !== '0' && <div className="christmas-reduction-tag">-30%</div>}
          </div>
          {/* PLAN PRICE INFOS */}

          <div className="text-price">
            <p className="font-sm">
              <FormattedMessage id="organization.startingAt" />
            </p>

            <div style={{ display: 'flex', alignItems: 'end' }}>
              <span
                className={'price'}
                style={{ marginRight: isEligibleForDiscount ? '5px' : '10px' }}
              >
                {isEligibleForDiscount &&
                // Don't show tag on free plan
                currentPlan.planId !== '0' ? (
                  <>
                    <span className="linethrough-value">
                      <NumberFormatter
                        value={
                          currentPlan.yearly
                            ? currentPlan.yearlyPricePerSeat
                            : currentPlan.pricePerSeat
                        }
                        user={user}
                        organizationCredits={organizationCredits}
                        currency={true}
                      />
                    </span>{' '}
                    <span
                      style={{
                        fontWeight: 500,
                      }}
                    >
                      <NumberFormatter
                        value={
                          currentPlan.yearly
                            ? Math.ceil(currentPlan.yearlyPricePerSeat * 0.7)
                            : Math.ceil(currentPlan.pricePerSeat * 0.7)
                        }
                        user={user}
                        organizationCredits={organizationCredits}
                        currency={true}
                        decimals={true}
                      />
                    </span>
                  </>
                ) : (
                  <span style={{ fontWeight: isEligibleForDiscount ? 500 : 700 }}>
                    <NumberFormatter
                      value={
                        currentPlan.yearly
                          ? currentPlan.yearlyPricePerSeat
                          : currentPlan.pricePerSeat
                      }
                      user={user}
                      organizationCredits={organizationCredits}
                      currency={true}
                    />
                  </span>
                )}
              </span>
              {currentPlan.planId !== '0' &&
                (isEligibleForDiscount && currentPlan.planId !== '0' ? (
                  <span
                    style={{
                      paddingBottom: '5px',
                      fontSize: '1rem',
                      fontWeight: 400,
                      color: '#111833',
                    }}
                  >
                    <FormattedMessage id={'billing.plan.perMonth'} />
                  </span>
                ) : (
                  <span className="font-sm" style={{ paddingBottom: '5px' }}>
                    <FormattedMessage id={'organization.perLicensePerMonth'} />
                  </span>
                ))}
            </div>
            {subscriptionType === 'monthly' && currentPlan.planId === 'plan_3' && (
              <div className="price-subtitle">
                <FormattedMessage id="billing.billedAnnually" />
              </div>
            )}
          </div>

          {/* CREDITS */}

          <div className="credits-section">
            <div className="credits-section-title">
              <h3>
                <FormattedMessage id="organization.activity.spentCredits" />{' '}
                {currentPlan.planId === '0' ? (
                  <span>
                    <FormattedMessage id="billing.plan.perMonth" />
                  </span>
                ) : currentPlan.planId === 'plan_3' ? (
                  <span>
                    <FormattedMessage id="billing.plan.perYear" />
                  </span>
                ) : (currentPlan.planId === 'plan_1' || currentPlan.planId === 'plan_2') &&
                  subscriptionType === 'yearly' ? (
                  <span>
                    <FormattedMessage id="billing.plan.perYear" />
                  </span>
                ) : (currentPlan.planId === 'plan_1' || currentPlan.planId === 'plan_2') &&
                  subscriptionType === 'monthly' ? (
                  <span>
                    <FormattedMessage id="billing.plan.perMonth" />
                  </span>
                ) : null}
              </h3>

              <CustomToolTip
                style={{
                  maxWidth: '20vw',
                }}
                position={currentPlan.planId === 'plan_3' ? 'left' : ''}
                element={
                  <FormattedMessage
                    //@ts-expect-error
                    id={tooltipIds[currentPlan.planId]}
                    values={{
                      strong: (chunks) => <strong>{chunks}</strong>,
                    }}
                  />
                }
              ></CustomToolTip>
            </div>

            <div className={'unlimited-b2b'}>
              <img src="/assets/images/star-vector.svg" alt="" />{' '}
              <FormattedMessage id={'billing.unlimitedB2bCredits'} />
              {currentPlan.planId === '0' && (
                <CustomToolTip
                  style={{
                    maxWidth: '20vw',
                  }}
                  position={currentPlan.planId === 'plan_3' ? 'left' : ''}
                  element={
                    <FormattedMessage
                      id={'home.onboarding.goldInvitesDesc'}
                      values={{
                        strong: (chunks) => <strong>{chunks}</strong>,
                      }}
                    />
                  }
                >
                  <img src="/assets/images/icon-info.svg" alt="info" />
                </CustomToolTip>
              )}
            </div>
          </div>

          {/* CREDITS SETTINGS */}

          <div className={'select-credits-container'}>
            <div
              className={'select-credits'}
              style={currentPlan.version == 1 ? { opacity: 0 } : {}}
            >
              <p>
                <FormattedMessage
                  id={'phoneCredits'}
                  values={{
                    value:
                      plan.phoneCreditsPerSeat *
                      (currentPlan.yearly ? 12 : 1) *
                      (currentPlan.planId === 'plan_3' ? 5 : 1),
                  }}
                />
              </p>

              <p>
                <FormattedMessage
                  id={'personalEmailCredits'}
                  values={{
                    value:
                      plan.personalEmailCreditsPerSeat *
                      (currentPlan.yearly ? 12 : 1) *
                      (currentPlan.planId === 'plan_3' ? 5 : 1),
                  }}
                />
              </p>

              <p>
                <FormattedMessage
                  id={'exportCredits'}
                  values={{
                    value:
                      plan.exportCreditsPerSeat *
                      (currentPlan.yearly ? 12 : 1) *
                      (currentPlan.planId === 'plan_3' ? 5 : 1),
                  }}
                />
              </p>
            </div>
            {currentPlan.version == 1 && (
              <div className={'select-credits legacy-cd'}>
                {currentPlan.legacyCreditsPerSeat} <FormattedMessage id={'credits'} />
              </div>
            )}
          </div>
        </div>

        <div className={'plan-card-content'}>
          {/* CURRENT PLAN / CHOOSE A PLAN */}
          {flags['learn-about-addons'] && currentPlan.planId !== '0' && <LearnAboutAddons />}
          {current && (
            <div className="cta-button-container">
              <a
                className="plan-btn current"
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <FormattedMessage id={'billing.currentPlan'} />

                <span>
                  <img src="/assets/images/red-tick.svg" width={14} height={14} />
                </span>
              </a>
            </div>
          )}
          {!current && currentPlan.planId === '0' && (
            <div className="cta-button-container">
              <a className="plan-btn" style={{ pointerEvents: 'none', opacity: '0.3' }}>
                <FormattedMessage id={'billing.plan.backToFree'} />
              </a>
            </div>
          )}
          {!current &&
            currentPlan.planId !== '0' &&
            currentPlan.planId.slice(-1) < organizationCredits.plan.planId.slice(-1) && (
              <div className="cta-button-container">
                <a className="plan-btn" onClick={() => handleSelectPlan(currentPlan, undefined)}>
                  <FormattedMessage id={'billing.choosePlan'} />
                </a>
              </div>
            )}
          {!current &&
            currentPlan.planId !== '0' &&
            (currentPlan.planId.slice(-1) > organizationCredits.plan.planId.slice(-1) ||
              currentPlan.planId.slice(-1) === organizationCredits.plan.planId.slice(-1)) && (
              <div className="cta-button-container">
                <a className="plan-btn" onClick={() => handleSelectPlan(currentPlan, undefined)}>
                  <FormattedMessage id={'billing.upgrade'} />
                </a>
              </div>
            )}

          {/* STATIC PLAN */}

          <div id="static-plan">
            <PlanPermissions staticPlan={staticPlan} currentPlan={currentPlan} />
          </div>
        </div>
      </div>

      <div id={'compare-plans-scroll'}></div>
    </div>
  );
}

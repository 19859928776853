import React from 'react';
import CustomToolTip from '../CustomToolTip/CustomToolTip';

export const truncateString = (text: string, maxLength: number | null = 20): string => {
  if (!text) return '';
  if (maxLength === null || maxLength <= 0) {
    return text;
  }
  return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
};

interface TruncatedTextProps {
  text: string;
  maxLength?: number;
  style?: React.CSSProperties;
}

const TruncatedText: React.FC<TruncatedTextProps> = ({ text, maxLength = 20, style = {} }) => {
  if (text.length < maxLength) {
    return <span style={style}>{text}</span>;
  }
  return (
    <CustomToolTip element={text}>
      <span style={style}>{truncateString(text, maxLength)}</span>
    </CustomToolTip>
  );

  // return (
  //   <span style={style} title={text}>
  //     {truncateString(text, maxLength)}
  //   </span>
  // );
};

export default TruncatedText;

import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

const AnchorPosition: React.FC<any> = ({ isOpen, anchorRef, children, onClose }) => {
  const dropdownRef = useRef(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (!isOpen || !anchorRef?.current) return;

    const updatePosition = () => {
      const anchorRect = anchorRef?.current?.getBoundingClientRect();
      if (anchorRect) {
        setPosition({
          top: anchorRect.top + window.scrollY, // Add scroll position to ensure proper placement
          left: anchorRect.left + window.scrollX,
        });
      }
    };

    updatePosition();
    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', () => {
      onClose(false);
      updatePosition();
    });

    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition);
    };
  }, [isOpen, anchorRef]);

  // Close dropdown when clicking outside
  // useEffect(() => {
  //   const handleClickOutside = (event: any) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target) && onClose) {
  //       onClose();
  //     }
  //   };
  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [onClose]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      ref={dropdownRef}
      style={{
        position: 'absolute',
        top: `${position.top}px`,
        left: `${position.left}px`,
        zIndex: 10,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        border: '1px solid #ccc',
      }}
    >
      {children}
    </div>,
    document.body,
  );
};

export default AnchorPosition;

import { FormattedMessage } from 'react-intl';
import PopUp from '../../../../../../../components/PopUp/PopUp';
import { useEffect, useState } from 'react';
import { calculateExtraCreditsPrice } from '../../../../../../../services/utils/calculatePrice';
import './AddonsPopup.css';
import NumberFormatter from '../../../../../../../components/NumberFormatter/NumberFormatter';
import { useSelector } from 'react-redux';
import { getCurrency } from '../../../../../../../services/utils/tools';
interface AddonType {
  amount: string;
  price: number;
  unitPrice: number;
}

const AddonsPopup: React.FC<any> = ({ onClose }) => {
  const [addons, setAddons] = useState([]);
  const [creditType, setCreditType] = useState<string>('phoneCredits');
  const [isAnnual, setIsAnnual] = useState<boolean>(true);
  const user = useSelector<any>((state) => state.profile || {});
  const customer = useSelector<any>(
    (state) => state.credits && state.credits?.organizationCredits?.customer,
  );

  console.log({ user });
  useEffect(() => {
    formatAddons({ isAnnual, creditType });
  }, []);

  const formatAddons = ({ isAnnual, creditType }: any) => {
    const allAddons = calculateExtraCreditsPrice({
      creditType,
      isAnnual,
    });
    let convertToArray = Object.assign([], allAddons.extraCredits);
    let addonsWithUnitPrice = convertToArray.map((credit: AddonType, i: string) => ({
      ...credit,
      unitPrice: (function () {
        const price = (
          calculateExtraCreditsPrice({
            creditType,
            isAnnual,
            amount: credit.amount,
            isUnitPrice: true,
          }) * 0.01
        ).toFixed(3);
        return price.endsWith('0') ? price.slice(0, -1) : price;
      })(),
    }));

    addonsWithUnitPrice = addonsWithUnitPrice.filter((addon: AddonType) => addon.price !== 0);

    setAddons(addonsWithUnitPrice);
  };

  const handleIsAnnual = (isAnnual: boolean) => {
    formatAddons({ isAnnual, creditType });
    setIsAnnual(isAnnual);
  };
  const handleCreditType = (creditType: string) => {
    formatAddons({ isAnnual, creditType });
    setCreditType(creditType);
  };

  return (
    <PopUp
      toggle={true}
      onClose={onClose}
      style={{
        width: '85vw',
        maxWidth: '500px',
        height: 'auto',
        position: 'absolute',
        top: '7vh',
      }}
    >
      <div className="about-addons-popup">
        <div>
          <h2 className="about-addons-popup__title">
            <FormattedMessage id="billing.addonCreditsPricing" />
          </h2>
          <p className="about-addons-popup__text">
            <FormattedMessage id="billing.youCanAddMoreCredits" />
          </p>
        </div>
        <div>
          <div className={'subscription-tabs about-addons-popup__switch'} style={{ margin: 0 }}>
            <ul className="nav nav-tabs" style={{ width: 'max-content' }}>
              <li onClick={() => handleIsAnnual(true)}>
                <a className={isAnnual ? 'active' : ''}>
                  <FormattedMessage
                    id={'billing.billAnnually'}
                    values={{
                      span: (chunk) => <span> </span>,
                    }}
                  />
                </a>
              </li>

              <li onClick={() => handleIsAnnual(false)}>
                <a className={!isAnnual ? 'active' : ''}>
                  <FormattedMessage id={'billing.billMonthly'} />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="about-addons-popup__tabs">
          <span
            onClick={() => handleCreditType('phoneCredits')}
            className={'' + (creditType === 'phoneCredits' ? 'active' : '')}
          >
            <FormattedMessage id="phone" />
          </span>
          <span
            onClick={() => handleCreditType('personalEmailCredits')}
            className={'' + (creditType === 'personalEmailCredits' ? 'active' : '')}
          >
            <FormattedMessage id="workflow.settings.directEmails" />
          </span>
          <span
            onClick={() => handleCreditType('exportCredits')}
            className={'' + (creditType === 'exportCredits' ? 'active' : '')}
          >
            <FormattedMessage id="export" />
          </span>
        </div>
        <table className="about-addons-popup__table">
          <thead>
            <tr>
              <td>
                <FormattedMessage id="credits" />
              </td>
              <td>
                {' '}
                <FormattedMessage id="billing.annualCost" />
              </td>
              <td>
                <FormattedMessage id="billing.pricePerCredit" />
              </td>
            </tr>
          </thead>
          <tbody>
            {addons.map((addon: AddonType) => (
              <tr>
                <td>
                  <NumberFormatter value={addon.amount} user={user} />
                </td>
                <td>
                  <NumberFormatter value={addon.price} user={user} />
                  {getCurrency(user, customer)}/
                  <span style={{ textTransform: 'lowercase' }}>
                    {isAnnual ? <FormattedMessage id="year" /> : <FormattedMessage id="month" />}
                  </span>{' '}
                </td>
                <td>
                  {addon.unitPrice}
                  {getCurrency(user, customer)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          className="about-addons-popup__switch"
          style={{ marginTop: 0, marginBottom: 0, paddingBottom: 4 }}
        >
          <button
            className="btn"
            style={{ color: '#4F65DA', fontWeight: 500 }}
            onClick={() => onClose(false)}
          >
            Close
          </button>
        </div>
      </div>
    </PopUp>
  );
};

export default AddonsPopup;

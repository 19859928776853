import React, { useContext, useEffect, useRef, useState } from 'react';
import { Tooltip } from '../../../../../components';

import { LeadsContext, actions } from '../../../store/leads.store';
import companyInfoData from '../../../LeadsInfo/Common/CompanyInfos/data';
import { FormattedMessage, useIntl } from 'react-intl';
import './LeadsItem.css';
import { imageExists } from '../../../../../services/utils/tools';
import { CREDIT_COLORS } from '../../../../../constants';
import CustomToolTip from '../../../../../components/CustomToolTip/CustomToolTip';
import { Animation } from '../../../../../components';
import { toast } from 'react-toastify';
import { deleteLead } from '../../../../../services/api/leads.api';
import { ModalMessage } from '../../../../../components';
import TruncatedText from '../../../../../components/TruncateString/TruncateString';
import { relative } from 'path';
import AnchorPosition from '../../../../../components/AnchorPosition/AnchorPosition';

function LeadsItem({
  lead,
  properties,
  onLeadSelect,
  handleTemplateToggle,
  onDataDiscover,
  isVersion2,
  organization,
  hidePersonalEmails,
  itemIndex,
  checkPlanPermissions,
  checkMemberPermissions,
  setTriggerUpdatedLeads,
  selectToggle,
  setSelectToggle,
  numberOfLeads,
}: any) {
  //@ts-expect-error
  const { dispatch } = useContext(LeadsContext);
  const checkbox_ref = useRef(null);
  const [picture, setPicture] = useState(null);
  const [workEmailToggle, setWorkEmailToggle] = useState(false);
  const [actionsToggle, setActionsToggle] = useState(false);
  const [phoneToggle, setPhoneToggle] = useState(false);
  const [directEmailToggle, setDirectEmailToggle] = useState(false);
  const [showMoreB2bEmailToggle, setShowMoreB2bEmailToggle] = useState(false);
  const [showMorePhoneToggle, setShowMorePhoneToggle] = useState(false);
  const [showMoreDirectEmailToggle, setShowMoreDirectEmailToggle] = useState(false);
  const [deleteLeadsToggle, setDeleteLeadsToggle] = useState(false);
  const anchorWorkEmailRef = useRef();
  const anchorPhoneRef = useRef();
  const anchorActionsRef = useRef();

  const intl = useIntl();
  useEffect(() => {
    const domain = lead.companyInfo && lead.companyInfo.domains && lead.companyInfo.domains[0];
    const url = 'https://logo.clearbit.com/' + domain;
    imageExists(url).then((result) => {
      if (result) setPicture(url);
      else setPicture('/assets/images/ic_profile.png');
    });
  }, []);

  useEffect(() => {
    if (!workEmailToggle) {
      setShowMoreB2bEmailToggle(false);
      setShowMoreDirectEmailToggle(false);
    }
    if (!phoneToggle) {
      setShowMorePhoneToggle(false);
    }
  }, [workEmailToggle, phoneToggle]);

  const toggleTemplate = (e: any) => {
    if (e) e.stopPropagation();
    handleTemplateToggle(lead);
  };
  const handleOnItemClick = (e: any, type: any) => {
    //@ts-expect-error
    if (type === 'view_details') return (window.location = '/lead/' + lead._id);
    else lead.isViewDetails = false;
    if (!lead.companyInfo) lead.companyInfo = companyInfoData;

    if (!checkbox_ref.current.contains(e.target)) {
      dispatch({ type: actions.SELECT_LEAD, payload: lead });
    }
  };

  const findProperty = (leadProperty: any) => {
    let prop = Object.assign({}, lead);
    if (!prop.tags) {
      prop.tags = [];
    }
    try {
      const splittedProperty = leadProperty.split('.');
      splittedProperty.forEach((p: any) => {
        prop = prop[p];
      });
      return prop;
    } catch (err) {}
  };

  const handleWorkCreditIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setWorkEmailToggle(true);
  };

  const handleActionsClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setActionsToggle(true);
  };

  const handlPhoneIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setPhoneToggle(true);
  };

  const copyToClipboard = (e: React.MouseEvent, text: string) => {
    e.stopPropagation();
    if (text) {
      navigator.clipboard.writeText(text);
      toast.success('Copié dans le presse-papiers !');
      setWorkEmailToggle(false);
      setPhoneToggle(false);
    }
  };

  const handleDeleteLeads = async (e: React.MouseEvent) => {
    if (!checkMemberPermissions('Export')) {
      return toast.error(<FormattedMessage id={'permissions.noAccess'} />);
    }

    if (!checkPlanPermissions('Export')) {
      return toast.error(<FormattedMessage id={'permissions.upgradePlan'} />);
    }

    const { data, statusCode } = await deleteLead([lead._id]);
    if (statusCode == 200) {
      setTriggerUpdatedLeads((prevState: any) => !prevState);
    }
    setDeleteLeadsToggle(false);
  };

  const lastLeadLine = itemIndex === numberOfLeads - 1;

  return (
    <>
      <tr
        onClick={(e) => {
          e.stopPropagation();
          handleOnItemClick(e, 'quick_View');
        }}
        // style={{ zIndex: itemIndex * -1 + 1000 }}
      >
        <td
          className={'checkbox-container white'}
          // style={{ zIndex: itemIndex * -1 + 1000 }}
        >
          <div className="checkbox-wrap" ref={checkbox_ref}>
            <label>
              <input
                type="checkbox"
                checked={lead.isChecked}
                onChange={(e) => onLeadSelect(e.target.checked, lead)}
              />

              <span className="checkbox-icon"></span>
            </label>
          </div>

          <div className="username view-profile">
            <span className="squared-logo">
              <img src={picture} width={30} height={'100%'} alt="" />
            </span>
            <div className="prospect-inner">
              <div className="prospect-col">
                <a
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOnItemClick(e, 'view_details');
                  }}
                  href={'http://linkedin.com/in/' + lead.linkedin}
                  target={'_blank'}
                  data-tracking-id={'leads.leadsList-likedinProfile'}
                  rel="noopener noreferrer nofollow"
                  className="full-name"
                >
                  {lead.firstName + ' ' + lead.lastName}
                </a>
                {lead['job'] && (
                  <div>
                    <TruncatedText
                      text={lead['job']}
                      maxLength={30}
                      style={{
                        fontWeight: 400,
                        fontSize: '12px',
                        color: '#414766',
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="prospect-icons">
            <div className="work-email-credits-ctn dropdown" style={{ position: 'relative' }}>
              <button
                className={`work-email-icon-btn ${workEmailToggle ? 'is-icon-active' : ''}`}
                onClick={(e) => handleWorkCreditIconClick(e)}
                ref={anchorWorkEmailRef}
              >
                <img src="/assets/images/fa-mail.svg" width={22} height={22} alt="Mail" />
              </button>
              <AnchorPosition
                onClose={setWorkEmailToggle}
                isOpen={workEmailToggle}
                anchorRef={anchorWorkEmailRef}
              >
                <Animation.Appear
                  setToggle={setWorkEmailToggle}
                  toggle={workEmailToggle}
                  className={'dropdown-menu show'}
                  style={{
                    top: '2rem',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    padding: 0,
                  }}
                  listStyle={{
                    overflow: 'auto',
                    height: 'auto',
                    width: 'auto',
                  }}
                >
                  <div
                    style={{
                      marginBottom: findProperty('currentProEmails').length === 1 ? '0.5rem' : '',
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {/* B2B EMAIL EQUAL 0 */}
                    {findProperty('currentProEmails').length === 0 && (
                      <>
                        <div
                          className=""
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                            marginBottom: '0.5rem',
                          }}
                        >
                          <img src="/assets/images/fa-mail.svg" width={22} height={22} alt="Mail" />

                          <p
                            style={{
                              fontSize: '13px',
                              fontWeight: 600,
                              color: '#797F99',
                            }}
                          >
                            <FormattedMessage id="workflow.settings.b2bEmails" />:
                          </p>
                        </div>
                        <div style={{ marginLeft: '1.8rem' }}>
                          <ShowDataButton
                            lead={lead}
                            type={'workEmailCredits'}
                            onDataDiscover={onDataDiscover}
                            isVersion2={isVersion2}
                          />
                        </div>
                      </>
                    )}

                    {findProperty('currentProEmails').length > 0 && (
                      <div
                        className=""
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '0.5rem',
                          marginBottom: '0.5rem',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                          }}
                        >
                          <img src="/assets/images/fa-mail.svg" width={22} height={22} alt="Mail" />

                          <p
                            style={{
                              fontSize: '13px',
                              fontWeight: 600,
                              color: '#797F99',
                            }}
                          >
                            <FormattedMessage id="workflow.settings.b2bEmails" />:
                          </p>
                        </div>

                        <div
                          style={{
                            marginLeft: '1.8rem',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                          }}
                        >
                          {findProperty('currentProEmails')[0]}

                          <button
                            className="copy-icon-btn"
                            onClick={(e) => copyToClipboard(e, findProperty('currentProEmails')[0])}
                          >
                            <img src="/assets/images/ic_copy.svg" width={15} height={15} alt="" />{' '}
                          </button>
                        </div>
                        {!showMoreB2bEmailToggle && findProperty('currentProEmails').length > 1 && (
                          <div>
                            <button
                              style={{
                                fontSize: '12px',
                                fontWeight: 400,
                                color: '#9298B7',
                                marginLeft: '1.8rem',
                              }}
                              onClick={(e: any) => {
                                e.stopPropagation();
                                setShowMoreB2bEmailToggle(true);
                              }}
                            >
                              <FormattedMessage id="leads.showMoreEmails" />
                            </button>
                          </div>
                        )}
                        {showMoreB2bEmailToggle && findProperty('currentProEmails').length > 1 && (
                          <div
                            style={{
                              marginLeft: '1.8rem',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '0.5rem',
                            }}
                          >
                            {findProperty('currentProEmails')
                              .slice(1)
                              .map((proEmail: string) => (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div>{proEmail}</div>
                                  <button
                                    className="copy-icon-btn"
                                    onClick={(e) => copyToClipboard(e, proEmail)}
                                  >
                                    <img
                                      src="/assets/images/ic_copy.svg"
                                      width={15}
                                      height={15}
                                      alt=""
                                    />{' '}
                                  </button>{' '}
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    )}

                    {/* DIRECT EMAILS */}
                    {!hidePersonalEmails && (
                      <>
                        <div className="direct-email-ctn">
                          <img
                            src="/assets/images/direct-email.svg"
                            width={18}
                            height={18}
                            alt="Mail"
                          />

                          <p className="">
                            <FormattedMessage id="workflow.settings.directEmails" />:
                          </p>
                        </div>

                        {findProperty('currentPersonalEmails').length === 0 ? (
                          <div style={{ marginLeft: '1.8rem' }}>
                            <ShowDataButton
                              lead={lead}
                              type={'personalEmailCredits'}
                              onDataDiscover={onDataDiscover}
                              isVersion2={isVersion2}
                            />
                          </div>
                        ) : (
                          <>
                            <div className="show-personal-email">
                              {findProperty('currentPersonalEmails')[0]}

                              <button
                                onClick={(e) =>
                                  copyToClipboard(e, findProperty('currentPersonalEmails')[0])
                                }
                                className="copy-icon-btn"
                              >
                                <img
                                  src="/assets/images/ic_copy.svg"
                                  width={15}
                                  height={15}
                                  alt=""
                                />{' '}
                              </button>
                            </div>
                            {!showMoreDirectEmailToggle &&
                              findProperty('currentPersonalEmails').length > 1 && (
                                <div>
                                  <button
                                    className="more-direct-email-btn"
                                    onClick={(e: any) => {
                                      e.stopPropagation();
                                      setShowMoreDirectEmailToggle(true);
                                    }}
                                  >
                                    <FormattedMessage id="leads.showMoreDirectEmails" />
                                  </button>
                                </div>
                              )}

                            {showMoreDirectEmailToggle &&
                              findProperty('currentPersonalEmails').length > 1 && (
                                <div className="more-direct-email">
                                  {findProperty('currentPersonalEmails')
                                    .slice(1)
                                    .map((personalEmail: string) => (
                                      <div className="show-personal-email">
                                        <div>
                                          <div>{personalEmail}</div>

                                          <button
                                            onClick={(e) => copyToClipboard(e, personalEmail)}
                                            className="copy-icon-btn"
                                          >
                                            <img
                                              src="/assets/images/ic_copy.svg"
                                              width={15}
                                              height={15}
                                              alt=""
                                            />{' '}
                                          </button>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </Animation.Appear>
              </AnchorPosition>

              {!findProperty('currentProEmail') && (
                <img
                  src="/assets/images/work-email-credits.svg"
                  alt="work email icon"
                  width={18}
                  height={18}
                  className="work-email-icon-img"
                />
              )}
            </div>
            {/* PHONE ICON */}
            <div className="dropdown">
              <button
                className={`phone-icon-btn ${phoneToggle ? 'is-icon-active' : ''}`}
                onClick={(e) => handlPhoneIconClick(e)}
                ref={anchorPhoneRef}
              >
                <img src="/assets/images/fa-phone.svg" alt="Phone" />
              </button>
              <AnchorPosition
                onClose={setPhoneToggle}
                isOpen={phoneToggle}
                anchorRef={anchorPhoneRef}
              >
                <Animation.Appear
                  setToggle={setPhoneToggle}
                  toggle={phoneToggle}
                  className={'dropdown-menu show'}
                  style={{
                    top: '2rem',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    padding: 0,
                  }}
                  listStyle={{
                    overflow: 'auto',
                    height: 'auto',
                    width: 'auto',
                  }}
                >
                  {findProperty('phones').length > 0 && (
                    <div
                      style={{
                        marginBottom: findProperty('phones').length === 1 ? '0.5rem' : '',
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="phone-modal">
                        <img src="/assets/images/fa-phone.svg" width={22} height={22} alt="Mail" />

                        <p>
                          <FormattedMessage id="workflow.settings.phoneNumbers" />:
                        </p>
                      </div>
                      <div
                        className="phone-modal-top"
                        style={{
                          marginBottom: showMorePhoneToggle ? '0.5rem' : '',
                        }}
                      >
                        {findProperty('phones')[0]}
                        <div className="phone-modal-icons">
                          <button
                            onClick={(e) => copyToClipboard(e, findProperty('phones')[0])}
                            className="copy-icon-btn"
                          >
                            <img src="/assets/images/ic_copy.svg" width={15} height={15} alt="" />{' '}
                          </button>
                        </div>
                      </div>
                      {!showMorePhoneToggle && findProperty('phones').length > 1 && (
                        <button
                          className="show-more-phone-btn"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setShowMorePhoneToggle(true);
                          }}
                        >
                          <FormattedMessage id="leads.showMorePhones" />
                        </button>
                      )}
                      {showMorePhoneToggle && findProperty('phones').length > 1 && (
                        <div className="show-more-phones-ul">
                          {findProperty('phones')
                            .slice(1)
                            .map((phone: string) => (
                              <div
                                className="phone-modal-top"
                                style={{
                                  marginBottom: showMorePhoneToggle ? '0.5rem' : '',
                                }}
                              >
                                {' '}
                                <div className="show-more-phone-li">
                                  <div>{phone}</div>

                                  <button
                                    onClick={(e) => copyToClipboard(e, phone)}
                                    className="copy-icon-btn"
                                  >
                                    <img
                                      src="/assets/images/ic_copy.svg"
                                      width={15}
                                      height={15}
                                      alt=""
                                    />{' '}
                                  </button>
                                </div>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  )}
                  {!findProperty('phone') && (
                    <div onClick={(e) => e.stopPropagation()}>
                      <div className="phone-number-ctn">
                        <img src="/assets/images/fa-mail.svg" width={22} height={22} alt="Mail" />

                        <p className="phone-number-ctn-text">
                          <FormattedMessage id="workflow.settings.phoneNumbers" />:
                        </p>
                      </div>
                      <div
                        className="phone-number-show-data-btn"
                        style={{
                          marginBottom: showMorePhoneToggle ? '0.5rem' : '',
                        }}
                      >
                        <ShowDataButton
                          lead={lead}
                          type={'phoneCredits'}
                          onDataDiscover={onDataDiscover}
                          isVersion2={isVersion2}
                        />
                      </div>
                    </div>
                  )}
                </Animation.Appear>
              </AnchorPosition>
              {!findProperty('phone') && (
                <img
                  src="/assets/images/phone-credits.svg"
                  alt="work email icon"
                  width={18}
                  height={18}
                  className="work-email-icon-img"
                />
              )}
            </div>
            {/* LINKEDIN ICON */}
            <a
              className="linkedin-icon-btn"
              href={'http://linkedin.com/in/' + lead.linkedin}
              target="_blank"
              onClick={(e) => {
                e.stopPropagation();
              }}
              rel="noopener noreferrer"
            >
              <img
                src="/assets/images/fa-linkedin.svg"
                width={18}
                height={18}
                alt="Linkedin icon"
              />
            </a>
            {/* ACTIONS ICON */}
            <div className="dropdown">
              <button
                className={`actions-icon-btn ${actionsToggle ? 'is-icon-active' : ''}`}
                onClick={(e) => handleActionsClick(e)}
                ref={anchorActionsRef}
              >
                <img src="/assets/images/fa-dots.svg" alt="Actions" />
              </button>
              <AnchorPosition
                onClose={setActionsToggle}
                isOpen={actionsToggle}
                anchorRef={anchorActionsRef}
              >
                <Animation.Appear
                  setToggle={setActionsToggle}
                  toggle={actionsToggle}
                  className={'dropdown-menu show'}
                  style={{
                    top: '2rem',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    padding: 0,
                    paddingBottom: 0,
                  }}
                  listStyle={{
                    overflow: 'auto',
                    height: 'auto',
                    width: 'auto',
                    padding: '10px 0',
                  }}
                >
                  <ul className="actions-ctn" onClick={(e) => e.stopPropagation()}>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOnItemClick(e, 'view_details');
                      }}
                    >
                      <FormattedMessage id="leads.openProfile" />
                    </li>
                    <li
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setDeleteLeadsToggle(true);
                      }}
                    >
                      <FormattedMessage id="delete" />
                    </li>
                  </ul>
                </Animation.Appear>
              </AnchorPosition>
            </div>
          </div>
        </td>
        {properties.map((property: any, i: any) => {
          if (property.property === 'addedBy') {
            return (
              <td key={i}>
                <div>{lead[property.property].email}</div>
              </td>
            );
          }
          if (property.property === 'addedOn') {
            return (
              <td key={i}>
                <div>{new Date(lead['createdAt']).toLocaleDateString('fr-FR')}</div>
              </td>
            );
          }
          if (property.property === 'company') {
            return (
              <td key={i}>
                <div>{lead[property.property]}</div>
              </td>
            );
          }

          if (property.property === 'location') {
            return (
              <td key={i}>
                <div>{lead[property.property]}</div>
              </td>
            );
          }

          if (property.property == 'currentPersonalEmail' && !findProperty(property.property)) {
            return (
              <td key={i}>
                {!hidePersonalEmails ? (
                  <ShowDataButton
                    lead={lead}
                    type={'personalEmailCredits'}
                    onDataDiscover={onDataDiscover}
                    isVersion2={isVersion2}
                  />
                ) : (
                  <span></span>
                )}
              </td>
            );
          }
          if (property.property == 'currentProEmail' && !findProperty(property.property)) {
            return (
              <td key={i}>
                <ShowDataButton
                  lead={lead}
                  type={'workEmailCredits'}
                  onDataDiscover={onDataDiscover}
                  isVersion2={isVersion2}
                />
              </td>
            );
          }
          if (
            typeof findProperty(property.property) === 'string' ||
            typeof findProperty(property.property) === 'number' ||
            findProperty(property.property) === null
          ) {
            if (
              property.property == 'currentProEmail' ||
              property.property == 'currentPersonalEmail'
            ) {
              return (
                <td key={i}>
                  {findProperty(property.property + 's')[0]}
                  {findProperty(property.property + 's').length > 1 && (
                    <CustomToolTip
                      element={findProperty(property.property + 's').slice(1)}
                      position={itemIndex < 2 ? 'right' : 'top'}
                    >
                      <p className="contact-more tip">
                        {findProperty(property.property + 's').length - 1}
                      </p>
                    </CustomToolTip>
                  )}
                </td>
              );
            }

            return (
              <td key={i}>
                {findProperty(property.property) && findProperty(property.property).length > 25 ? (
                  <CustomToolTip element={findProperty(property.property)}>
                    <span>{findProperty(property.property).substring(0, 25) + '...'}</span>
                  </CustomToolTip>
                ) : (
                  <span>
                    {findProperty(property.property) === 'Default list' ? (
                      <FormattedMessage id={'leads.defaultList'} />
                    ) : (
                      findProperty(property.property)
                    )}{' '}
                  </span>
                )}
              </td>
            );
          }
          if (property.property === 'tags') {
            return (
              <td key={i}>
                <div style={{ width: 'max-content', display: 'inline-flex', alignItems: 'center' }}>
                  {findProperty(property.property)[0] &&
                    findProperty(property.property)[0].tagId && (
                      <span
                        style={{
                          background: '#edf0ff',
                          color: '#5369df',
                          borderRadius: 20,
                          margin: '5px 0',
                          padding: 5,
                        }}
                      >
                        {findProperty(property.property)[0].tagId?.value}{' '}
                      </span>
                    )}
                  {findProperty(property.property).length > 1 && (
                    <CustomToolTip
                      // position={itemIndex > 5 ? 'top' : 'bottom'}
                      // position={'bottom'}
                      scrollableClass="table-content"
                      element={
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {findProperty(property.property)
                            .map((tag: any, i: number) => (
                              <div key={i}>
                                <TruncatedText
                                  text={tag.tagId?.value}
                                  maxLength={15}
                                  style={{ color: 'white', fontSize: '14px' }}
                                />
                              </div>
                            ))
                            .slice(1)}
                        </div>
                      }
                    >
                      <p className="contact-more tip">
                        {findProperty(property.property).length - 1}
                      </p>
                    </CustomToolTip>
                  )}
                </div>
              </td>
            );
          }
          if (Array.isArray(findProperty(property.property))) {
            if (property.property == 'phones' && findProperty(property.property).length == 0) {
              return (
                <td key={i}>
                  <ShowDataButton
                    lead={lead}
                    type={'phoneCredits'}
                    onDataDiscover={onDataDiscover}
                    isVersion2={isVersion2}
                  />
                </td>
              );
            }

            return (
              <td key={i}>
                {findProperty(property.property)[0]}
                {findProperty(property.property).length > 1 && (
                  <CustomToolTip
                    element={findProperty(property.property).slice(1)}
                    // position={itemIndex < 2 ? 'right' : 'top'}
                    scrollableClass="table-content"
                  >
                    <p className="contact-more tip">{findProperty(property.property).length - 1}</p>
                  </CustomToolTip>
                )}
              </td>
            );
          }
        })}
      </tr>
      {deleteLeadsToggle && (
        <ModalMessage
          toggle={deleteLeadsToggle}
          setToggle={() => {
            setDeleteLeadsToggle(false);
          }}
          title={
            <>
              <FormattedMessage id={'leads.deleteLead'} />
            </>
          }
          textElement={
            <>
              <FormattedMessage id="leads.deleteLeadText" />
            </>
          }
          action={intl.formatMessage({ id: 'delete' })}
          handleAction={(e: any) => handleDeleteLeads(e)}
          tracking={{
            confirm: 'leads.deleteLead-confirm',
            cancel: 'leads.deleteLead-cancel',
          }}
        />
      )}
    </>
  );
}

function ShowDataButton({ lead, type, onDataDiscover, isVersion2 }: any) {
  const { state }: any = useContext(LeadsContext);
  const intl = useIntl();

  if (!isVersion2) return <span>--</span>;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onDataDiscover({ lead, type: CREDIT_COLORS[type].creditMap });
  };
  const tooltipTexts: any = {
    workEmailCredits: intl.formatMessage({ id: 'leads.emailNotFound' }),
    personalEmailCredits: intl.formatMessage({ id: 'leads.emailNotFound' }),
    phoneCredits: intl.formatMessage({ id: 'leads.phoneNotFound' }),
    directEmailCredits: intl.formatMessage({ id: 'leads.emailNotFound' }),
  };
  const isDataNotFound = state?.dataNotFound?.leads?.find(
    (item: any) => item._id === lead._id && item.creditType === CREDIT_COLORS[type].creditMap,
  );

  if (isDataNotFound) {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <CustomToolTip element={tooltipTexts[type]}>
          <button
            className={'btn btn2 show-data'}
            onClick={(e) => e.stopPropagation()}
            style={{
              borderColor: 'CREDIT_COLORS[type].color',
              color: CREDIT_COLORS[type].color,
              opacity: 0.5,
              pointerEvents: 'none',
              cursor: 'pointer',
            }}
          >
            <img src={CREDIT_COLORS[type].icon} alt="" />{' '}
            <FormattedMessage id={'show'} defaultMessage={'Show'} />
          </button>
        </CustomToolTip>
      </div>
    );
  }

  return (
    <button
      className={'btn btn2 show-data'}
      onClick={handleClick}
      style={{
        borderColor: CREDIT_COLORS[type].color,
        color: CREDIT_COLORS[type].color,
      }}
    >
      <img src={CREDIT_COLORS[type].icon} alt="" />{' '}
      <FormattedMessage id={'show'} defaultMessage={'Show'} />
    </button>
  );
}

export default LeadsItem;

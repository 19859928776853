import React, { useEffect, useState } from 'react';
import { Animation } from '../';
import './SelectPicker.css';
import { FormattedMessage } from 'react-intl';
function SelectPicker({
  values = [],
  onChange,
  labelsStyle,
  isCreateOrg,
  zIndex,
  withId,
  id,
  selectedLabelStyle,
  staticLabel,
  lock,
  disabled,
  lockExportCrm,
  color,
  doCloseOnSelect,
  doNotCapitalize,
  type,
}: any) {
  const [selectedLabel, setSelectedLabel] = useState(values[0]);
  const [selectToggle, setSelectToggle] = useState(false);
  useEffect(() => {
    const selectedId = values.find((value: { id: string }) => value.id === id);
    if (selectedId) {
      setSelectedLabel(selectedId);
    }
  }, [id]);
  const handleSelectLabel = (value: any) => {
    setSelectedLabel(value);
    if (withId) {
      if (!staticLabel || doCloseOnSelect) setSelectToggle(false);
      onChange(value.id);
    } else {
      if (!staticLabel || doCloseOnSelect) setSelectToggle(false);
      onChange(value);
    }
  };

  return (
    <div className={'custom-select-picker'} style={zIndex ? { zIndex } : {}}>
      <div
        className={'dropdown-toggle ' + (isCreateOrg ? 'selected-label-org' : 'selected-label')}
        onClick={disabled ? () => false : () => setSelectToggle(true)}
        style={{
          ...selectedLabelStyle,
          ...(disabled ? { opacity: 0.5 } : {}),
          color,
        }}
      >
        {disabled && (
          <img src="/assets/images/lock.png" style={{ width: 15, marginRight: 7 }} alt="" />
        )}
        {staticLabel ? (
          staticLabel
        ) : withId ? (
          selectedLabel.name
        ) : type && type === 'sortBy' ? (
          <FormattedMessage id={'filters.' + selectedLabel} />
        ) : (
          selectedLabel
        )}
      </div>

      <Animation.SlideDown
        className={'labels'}
        toggle={selectToggle}
        setToggle={setSelectToggle}
        style={labelsStyle}
        closeSelect={isCreateOrg}
        withoutScroll={true}
      >
        {withId ? (
          <ul>
            {values.map((value: { id: string; name: string }, i: string) => (
              <li
                key={i}
                style={
                  value.id === selectedLabel.id
                    ? { background: '#3B53D1', color: '#fff', width: '100%' }
                    : { width: '100%' }
                }
                onClick={() => handleSelectLabel(value)}
              >
                {value.name}
              </li>
            ))}
          </ul>
        ) : (
          <ul>
            {values.map((value: string, i: string) => (
              <li
                className={!doNotCapitalize ? 'caps' : ''}
                key={i}
                style={value === selectedLabel ? { background: '#3B53D1', color: '#fff' } : {}}
                onClick={
                  lockExportCrm && value == 'Integrations'
                    ? () => false
                    : () => handleSelectLabel(value)
                }
              >
                {lock ||
                  (lockExportCrm && value == 'Integrations' && (
                    <img
                      src="assets/images/lockBlack.png"
                      style={{ width: 15, marginRight: 7 }}
                      alt=""
                    />
                  ))}{' '}
                {type && type === 'sortBy' ? <FormattedMessage id={'filters.' + value} /> : value}
              </li>
            ))}
          </ul>
        )}
      </Animation.SlideDown>
    </div>
  );
}

export default SelectPicker;

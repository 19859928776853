import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import './AllListsNav.css';
import CreateNewListModal from '../CreateNewListModal/CreateNwListModal';
import { capitalizeFirstLetter } from '../../../../services/utils/tools';
import { addLeadTag } from '../../../../services/api/leads.api';
import { LeadsContext } from '../../store/leads.store';
import { actions } from '../../store/leads.store';
import { toast, ToastContainer } from 'react-toastify';
import { getMembers } from '../../../../services/api/organization.api';

const AllListsNav = ({
  handleSearchChange,
  searchTerm,
  handleOwnerSelect,
  selectedOwner,
  owners,
  showDetailedList,
  setShowDetailedList,
  setFilteredTags,
  onTagUpdated,
  setTriggerGetTags,
}: any) => {
  const [ownerToggle, setOwnerToggle] = useState(false);
  const [addedBySearch, setAddedBySearch] = useState('');
  const [showCreateAListModal, setShowCreateAListModal] = useState(false);
  const intl = useIntl();
  const [creationLoading, setCreationLoading] = useState(false);
  //@ts-expect-error
  const { state, dispatch } = useContext(LeadsContext);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [members, setMembers] = useState([]);

  const fetchMembers = useCallback(async (searchTerm = '') => {
    const { data, statusCode } = await getMembers({ search: searchTerm });
    if (statusCode === 200) {
      //@ts-expect-error
      setMembers(data.members);
    }
  }, []);

  useEffect(() => {
    // Initial fetch without search
    fetchMembers('');
  }, [fetchMembers]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (addedBySearch.trim() === '') {
        fetchMembers('');
      } else {
        fetchMembers(addedBySearch.trim());
      }
    }, 300); // Avoid too many requests

    return () => clearTimeout(timer);
  }, [addedBySearch, fetchMembers]);

  const addedByFilter = ({ search }: { search: string }) => {
    setAddedBySearch(search);
  };

  const handleOwnerSelectLocal = (owner: any) => {
    handleOwnerSelect(owner);
    setAddedBySearch('');
    setOwnerToggle(false);
  };

  const handleCreateList = async (listName: any) => {
    if (creationLoading) return;
    setCreationLoading(true);
    const { data, statusCode } = await addLeadTag({
      value: listName,
      color: '',
      clientDate: new Date(),
    });
    setCreationLoading(false);

    if (statusCode == 200) {
      setFilteredTags((prevTags: any) => {
        //@ts-expect-error
        const newTags = prevTags.filter((t: any) => t._id !== data.tag._id);
        dispatch({
          type: actions.GET_ALL_TAGS,
          payload: newTags,
        });
        return newTags;
      });
      toast.success(
        intl.formatMessage({
          id: 'leads.listSuccessfullyCreated',
          defaultMessage: 'List successfully created',
        }),
      );
      setShowCreateAListModal(null);
      //@ts-expect-error
      if (data && data.tag && onTagUpdated) {
        //@ts-expect-error
        onTagUpdated(data.tag);
      }
    } else {
      if (
        //@ts-expect-error
        data.message === 'Tag already exists' ||
        //@ts-expect-error
        data.messageTranslated === 'Tag already exists'
      ) {
        toast.error(<FormattedMessage id="toast.listAlreadyExists" />);
      }
    }
  };

  const handleClickBackBtn = () => {
    setTriggerGetTags((prevState: any) => !prevState);
    setShowDetailedList(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOwnerToggle(false);
      }
    };

    if (ownerToggle) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ownerToggle]);

  return (
    <div className="all-lists-nav-container ">
      {showDetailedList && (
        <div className="all-lists-top-nav">
          <div className="btn-wrapper" onClick={handleClickBackBtn}>
            <img src="/assets/images/back-arrow-icon.svg" alt="" />
            <div className="all-lists-back-btn">
              <FormattedMessage id="back" />
            </div>
          </div>
          {state.selectedList && (
            <>
              <span>|</span>

              <p>{state.selectedList.value}</p>
            </>
          )}
        </div>
      )}
      {!showDetailedList && (
        <div className="all-lists-nav-container__ctn">
          <div className="all-lists-nav-container__left">
            {/* Search in all lists */}
            <div
              className="all-lists-nav-container__left__search search-ui"
              data-tracking-id={'leads.filters-searchInput'}
            >
              <FormattedMessage id={'leads.search'}>
                {(placeholder) => (
                  <input
                    type="text"
                    name="search"
                    //@ts-expect-error
                    placeholder={placeholder}
                    onChange={handleSearchChange}
                    value={searchTerm}
                    autoComplete={'off'}
                  />
                )}
              </FormattedMessage>
            </div>

            {/* Filter by owner */}
            <div className="cstm-dropdown addedby user_filter all-lists_dropdown" ref={dropdownRef}>
              <div className="dropdown">
                <Button
                  variant="primary"
                  className="dropdown-toggle"
                  onClick={() => setOwnerToggle(!ownerToggle)}
                  style={{ minWidth: 'max-content' }}
                  data-tracking-id="leads.filter-addedBy"
                >
                  <FormattedMessage id="leadsLists.ownedBy" />
                </Button>

                {ownerToggle && (
                  <div className="dropdown-menu show">
                    <div className="search-input">
                      <i className="fas fa-search"></i>
                      <input
                        type="text"
                        style={{ border: 'none' }}
                        placeholder={intl.formatMessage({ id: 'leads.search' })}
                        onChange={(e) => {
                          addedByFilter({ search: e.target.value });
                        }}
                        value={addedBySearch}
                      />
                    </div>

                    <ul>
                      <li
                        onClick={() => {
                          handleOwnerSelect(null);
                          setAddedBySearch('');
                          setOwnerToggle(false);
                        }}
                      >
                        <a href="#" data-tracking-id="leads.filter-addedBy-all">
                          <span
                            className="name_tag light_red first-all"
                            style={{ textTransform: 'uppercase' }}
                          >
                            <FormattedMessage id="leads.all" />
                          </span>
                          <span className="name_ui" style={{ color: '#3b53d1' }}>
                            <FormattedMessage id="organization.allMembers" />
                          </span>
                        </a>
                        <hr />
                      </li>
                      {members.length > 0 ? (
                        members.map((owner: any, i: number) => (
                          <li key={i} onClick={() => handleOwnerSelectLocal(owner)}>
                            <a href="#">
                              <span className="name_ui">
                                {owner.firstName && owner.lastName
                                  ? `${capitalizeFirstLetter(owner.firstName)} ${capitalizeFirstLetter(owner.lastName)}`
                                  : owner.email}
                              </span>
                            </a>
                          </li>
                        ))
                      ) : (
                        <li style={{ textAlign: 'center' }}>
                          <a href="#">
                            <span className="name_ui">
                              <FormattedMessage id="workflow.noResultsFound" />
                            </span>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          <button className="btn1" onClick={() => setShowCreateAListModal(true)}>
            <a className="border-plus-icon">
              <img src="/assets/images/ic_insert_variable-w.svg" alt="" />
            </a>

            <FormattedMessage id={'leadsLists.createAList'} />
          </button>
        </div>
      )}
      <ToastContainer />
      {showCreateAListModal && (
        <CreateNewListModal
          toggle={showCreateAListModal}
          setToggle={setShowCreateAListModal}
          tracking={{
            action: 'leads.filters-list-createNewListPopUp-create',
            cancel: 'leads.filters-list-createNewListPopUp-cancel',
          }}
          handleCreateList={handleCreateList}
        />
      )}
    </div>
  );
};

export default AllListsNav;
